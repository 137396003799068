* {
  margin: 0;
  padding: 0;
}
/* font-family: 'BOG 2017 Headline'; */
/* font-family: 'BOG 2018' */
@font-face {
  font-family: "BOG 2017 Headline";
  src: url("./assets/fonts/BOG2017Headline-SemiBold.eot");
  src: url("./assets/fonts/BOG2017Headline-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/BOG2017Headline-SemiBold.woff2") format("woff2"),
    url("./assets/fonts/BOG2017Headline-SemiBold.woff") format("woff"),
    url("./assets/fonts/BOG2017Headline-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "BOG 2018";
  src: url("./assets/fonts/BOG2018-Medium.eot");
  src: url("./assets/fonts/BOG2018-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/BOG2018-Medium.woff2") format("woff2"),
    url("./assets/fonts/BOG2018-Medium.woff") format("woff"),
    url("./assets/fonts/BOG2018-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

body {
  background-color: rgba(255, 255, 255, 0.456);
  font-family: "BOG 2018";
  width: 100%;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #E2E2E2;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
html {
  scroll-behavior: smooth;
}
@media (max-width: 350px) {
  body {
    background-repeat: repeat;
  }
}

.ant-select {
  font-size: 12px;
}

.ant-select-item-option-content {
  font-size: 12px;
}

.ant-pagination-item-active {
  border-color: #0e4c90;
}

.ant-pagination-item-active a {
  color: #0e4c90;
}

.ant-drawer-content-wrapper {
  z-index: 1111;
}
.ant-drawer-title {
  font-family: "BOG 2017 Headline";
  font-size: 12px;
  color: #0e4c90;
}

.ant-collapse-item {
  font-size: 12px !important;
  background: #fff;
  margin: 10px;
  border-radius: 6px !important;
  transition: 300ms;
}

.ant-collapse-header {
  font-family: "BOG 2017 Headline";
  font-size: 12px;
  color: #0e4c90 !important;
  transition: 300ms;
}
.ant-collapse-header:hover {
  color: #7f848a !important;
}
.ant-collapse-content-box {
  font-size: 12px;
  color: #7f848a !important;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  color: #0e4c90;
}

.ant-pagination.mini .ant-pagination-item {
  min-width: 30px;
  height: 30px;
  margin: 0;
  line-height: 30px;
  border-radius: 4px;
  font-size: 12px;
}

@media (max-width: 768px) {
  .ant-steps {
    margin-left: 15px;
  }
}

.ant-form-item input {
  font-size: 12px;
}

.ant-form-item textarea {
  font-size: 12px;
}

.ant-modal-title {
  font-family: "BOG 2017 Headline";
  font-size: 12px;
  color:#0e4c90 !important;
}
.ant-modal-title h4 {
  font-family: "BOG 2017 Headline";
  font-size: 12px;
  color:#0e4c90 !important;
}
.ant-input::placeholder {
  font-size:12px;
}

.ant-select {
  border-radius: 3px !important;
}
.ant-btn {
  border-radius: 3px !important;
}
.ant-input {
  border-radius: 3px !important;
}
.ant-menu-title-content {
  font-size:12px !important;
  color:#7F8991 !important;
} 
.ant-menu-item-selected .ant-menu-title-content {
  font-size:12px !important;
  color:#0e4c90 !important;
} 